<template>
  <div class="buy-message-page">
    <x-dialog :proxy="buyDialog">
      <el-form label-width="50px" style="margin-top: 16px">
        <el-form-item label="类型">
          <el-radio-group v-model="name" size="small">
            <el-radio-button label="蓝鲸短信"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="规格" error="true">
          <el-radio-group v-model="type_num" @change="handleChangeNum" size="small">
            <el-radio-button
              v-for="(item, index) in data"
              :key="item.id"
              :label="index"
            >
              {{ item.num + '条' }}
            </el-radio-button>
          </el-radio-group>

          <div slot="error" style="line-height: 1.5; font-size: 12px; color: #666">
            短信条数购买成功后，即可生效，使用后剩余条数不支持退订
          </div>
        </el-form-item>

        <div class="title-flag mb-2">
          已选配置
        </div>

        <el-form-item label="类型" class="m-0">
          {{ type_obj.name }}
        </el-form-item>
        <el-form-item label="规格" class="m-0">
          <span style="color: #333; font-size: 18px; margin-right: 4px">{{ type_obj.num }}</span>
          <span style="color: #333; font-size: 13px">条</span>
        </el-form-item>
        <el-form-item label="费用" class="m-0">
          <span style="color: #ec3a33; font-size: 13px">￥</span>
          <span style="color: #ec3a33; font-size: 18px">{{ type_obj.price }}</span>
        </el-form-item>
      </el-form>
    </x-dialog>

    <x-dialog :proxy="buyConfirmDialog">
      <el-form class="mt-2" label-width="80px">
        <el-form-item label="短信名称" class="m-0">
          {{ pay_item.name }}
        </el-form-item>
        <el-form-item label="短信规格" class="m-0">
          <span style="color: #333; font-size: 18px; margin-right: 4px">{{ pay_item.num }}</span>
          <span style="color: #333; font-size: 13px">条</span>
        </el-form-item>
        <el-form-item label="支付" class="m-0">
          <span style="color: #ec3a33; font-size: 13px">￥</span>
          <span style="color: #ec3a33; font-size: 18px">{{ pay_item.price }}</span>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_Account from '@/api/account';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  components: {XDialog},
  data() {
    return {
      buyDialog: $xDialog.create({
        title: '短信套餐',
        beforeConfirm: () => this.czShow(this.type_obj),
      }),
      buyConfirmDialog: $xDialog.create({
        title: '确认购买',
        width: '300px',
        beforeConfirm: () => this.addLv(),
      }),
      type_num: '0',
      name: '蓝鲸短信',
      data: [],
      type_obj: {},

      // 创建服务订单接口数据
      params: {
        service_type: 1,
        aim_id: 0
      },
      // 确认支付接口数据
      order_params: {
        payment_plugin_id: 'shopBalancePlugin',
        pay_mode: 'normal',
        client_type: 'PC',
        trade_type: 'service'
      },
      // 创建服务订单接口返回的订单号
      sn: '',
      // 确认支付框
      dialogVisibleg: false,

      pay_item: {}
    };
  },
  watch: {
    type_num(newVlue, oldValue) {
    }
  },
  mounted() {
    API_Account.GET_shopSmsgetAll({name: '蓝鲸短信'}).then(res => {
      this.data = res;
      this.type_obj = this.data[0];
    });
  },
  methods: {
    show() {
      this.buyDialog.display();
    },
    addLv() {
      API_Account.pay(this.order_params, this.sn).then(res => {
        console.log(res, 'this.order_params');
        let callBackParams = {
          pay_order_no: res.pay_order_no,
          need_pay_money: res.need_pay_money,
          out_trade_no: res.out_trade_no
        };
        API_Account.callBack(res.call_back_url, callBackParams).then(resp => {
          this.dialogVisibleg = false;
          this.$message.success('支付成功');
          // this.GET_List();
          this.buyDialog.dismiss();
          this.$parent.getMsgData();
        });
      });
    },
    // 立即购买
    czShow(item) {
      this.params.aim_id = item.id;
      API_Account.createOrder(this.params).then(res => {
        this.sn = res;
        this.pay_item = {
          ...this.pay_item,
          ...item,
        };

        this.buyConfirmDialog.display();
      });
      return false;
    },
    // 点击规格
    handleChangeNum() {
      this.type_obj = this.data[this.type_num];
    }
  }
};
</script>
<style lang="scss" scoped>
.gobuy {
  margin-left: 150px;
}

.tipBox .msss {
  padding-left: 150px;
  color: #000000;
  width: 500px;
}

.fontbig {
  font-size: 18px;
}

.btn {
  margin-left: 20px;
}

.btn .el-radio-button__inner {
  border-left: 1px solid #dcdfe6 !important;
  border-radius: 4px;
  box-shadow: none !important;
}

.row1 {
  padding-left: 80px;
  margin-top: 25px;
  font-size: 14px;
}

.tipBox {
  background: #fff;
  padding: 20px;
  display: inline-block;
  width: 80%;
  margin-top: 20px;
}

.tipBox p {
  color: #666666;
}

ul li {
  list-style: none;
}
</style>
